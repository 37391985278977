import React, { Component } from "react";
// import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { css } from "@emotion/core";
import gsap from "gsap";
import SEO from "../components/Seo/Seo";
// import { random } from "gsap/gsap-core";

class SkillsPage extends Component {
  constructor(props) {
    super(props);

    // this.myAnimation = new gsap.timeline({ paused: true });

    // this.backgroundColor = "#fff";
    this.mySkills = [];
  }

  componentDidMount() {
    this.myAnimation = new gsap.timeline()
      .fromTo(
        "p",
        { opacity: 0, y: 9, x: 0, rotation: 0 },
        {
          opacity: 1,
          y: 0,
          x: 0,
          rotation: 0,
          duration: 1,
          stagger: 0.1,
          ease: gsap.back,
        }
      )
      .fromTo(
        ".fa-check",
        { opacity: 0, scale: 0.2 },
        {
          opacity: 1,
          scale: 1,
          stagger: 0.1,
          ease: gsap.back,
          duration: 0.3,
        },
        "1"
      );
    // console.log(b"here:", this.mySkills);
  }

  render() {
    const skills = [
      {
        name: "Javascript",
        skillsList: [
          "ES6",
          "JQuery",
          "npm/yarn",
          "ReactJS",
          "VueJS",
          "GatsbyJS",
          "GSAP animations",
          "Lottie animations",
          "lodash",
        ],
      },
      {
        name: "CSS",
        skillsList: [
          "SASS/LESS",
          "Post/Pre processing Gulp/Grunt/Webpack",
          "CSS animations",
          "Cross-browsers testing",
          "Browser backwards compatibility",
          "ITCSS, BEM",
          "Modular CSS, styled components",
          "Bootstrap",
        ],
      },
      {
        name: "HTML",
        skillsList: [
          "Web standards",
          "Accessability",
          "SEO optimisation",
          "HTML emails",
        ],
      },
      {
        name: "There is more...",
        skillsList: [
          "Git and version control workflow",
          "Live deployment",
          "Team player",
          "Python",
          "Django",
          "Wagtail",
          "Wordpress",
          "Postgres",
          "SQL",
          "GraphQL",
          "Docker",
          "Excellent communication",
          "Work with UX/UI Designers",
          "Illustrator",
          "Photoshop",
          "Sketch",
          "After Effects",
        ],
      },
    ];
    return (
      <div className="container">
        <div className="center-content">
          <SEO title="Skills" />
          <h1 className="page-title">SKILLS</h1>
          <p className="page-info">
            I have been interested in web developement since 2015 and have
            worked on projects of varying size. Along the way, I have picked up
            some valuable skills. <br />
            <br /> Here they are...
          </p>
          {skills.map((group, groupIndex) => {
            // console.log("first loop", group, groupIndex);
            return (
              <div key={groupIndex}>
                <div
                  css={css`
                    width: 100%;

                    margin-bottom: 40px;
                    h3 {
                      margin-bottom: 0;
                    }
                  `}
                >
                  <h2>{group.name}</h2>
                  <ul
                    css={css`
                      list-style-type: none;
                      margin: 0;
                      padding: 0;
                      display: flex;
                      flex-wrap: wrap;

                      align-items: flex-start;
                      justify-content: center;
                    `}
                  >
                    {group.skillsList.map((item, i) => {
                      // console.log("imeheresfasfd", item);
                      return (
                        <li
                          key={i}
                          ref={li => (this.mySkills[item] = li)}
                          css={css`
                            margin: 0;
                            padding: 0;

                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            margin: 0 10px;
                          `}
                        >
                          <p>
                            {item}

                            <FontAwesomeIcon
                              css={css`
                                /* font-size: 0.4rem; */
                                margin-left: 5px;
                              `}
                              icon="check"
                              size="sm"
                              color="#ff7601"
                            />
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                {/* {group.skillList.map(skill => {
                return <span>{skill}</span>;
              })} */}
              </div>
            );
          })}
          <p>How did I use all these skills?</p>
          <AniLink fade duration={1} to="/projects/">
            <button className="btn">
              <FontAwesomeIcon icon={["fas", "folder-open"]} size="sm" />
              Projects{" "}
            </button>
          </AniLink>
        </div>
      </div>
    );
  }
}
export default SkillsPage;
